/* montserrat-latin-ext-100-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url('./files/montserrat-latin-ext-100-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-100-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-200-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url('./files/montserrat-latin-ext-200-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-200-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-300-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/montserrat-latin-ext-300-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-300-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-400-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./files/montserrat-latin-ext-400-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-400-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-500-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./files/montserrat-latin-ext-500-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-500-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-600-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('./files/montserrat-latin-ext-600-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-600-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-700-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./files/montserrat-latin-ext-700-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-700-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-800-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('./files/montserrat-latin-ext-800-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-800-normal.woff') format('woff');
  
}
/* montserrat-latin-ext-900-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('./files/montserrat-latin-ext-900-normal.woff2') format('woff2'), url('./files/montserrat-latin-ext-900-normal.woff') format('woff');
  
}
